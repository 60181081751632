import React from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import App from "./containers/App";
import Home from "./routes/Home/Home";
import './index.css'
import 'antd-css-utilities/utility.min.css'
import Configurator from "./routes/Configurator/Configurator";
import ErrorPage from "./components/Errors/ErrorPage";
import { deferredLoader as configuratorLoader } from "./routes/Configurator/loader";
import './i18n';
import PrivacyContacts from "./routes/Privacy/PrivacyContacts";
import DataProtection from "./routes/Privacy/DataProtection";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/configurator",
                loader: configuratorLoader,
                element: <Configurator />,
            },
            {
                path: '/datenschutz',
                element: <DataProtection />,
            },
            {
                path: '/impressum',
                element: <PrivacyContacts />,
            }            
        ]
    },
]);

root.render(
    <RouterProvider router={router} />
);
import React, { useContext, useState, useMemo } from 'react';
import { ConfiguratorContext } from '../context';
import { Col, Row, Button, Radio, Typography, Space, Divider, Slider, Flex, Alert } from 'antd';
import { setQueryStringValuesWithoutNavigation } from '../../../utils/router';
import PageHeader from '../../../components/UI/PageHeader';
import { CheckOutlined } from '@ant-design/icons';
import './ProductVersionStep.css';
import { useTranslation } from 'react-i18next';
import ProductCard from '../../../components/Product/ProductCard';

const DEFAULT_FILTERS = {
    sides: null,
    width: null
};

const FILTERS_DISABLED = true;

const ProductsList = ({ dataSource, selectProductVersion, t }) => {

    if (!dataSource.length) {
        return (
            <Col xs={24}>
                <Alert
                    message={t('configurator.search_results')}
                    description={t('configurator.no_products_found')}
                    type="warning"
                    showIcon
                />
            </Col>
        );
    }


    return dataSource
        .map((productVersion, index) => {

            return (
                <Col key={index} xs={24} md={12} lg={12} xl={12} xxl={8}>

                    <ProductCard
                        coverClassName='product-cover'                        
                        product={productVersion}
                        sizeTabTitle={t('configurator.dimensions')}
                        actions={[
                            <Button
                                icon={<CheckOutlined />}
                                type="primary" key={`sv-${index}`}
                                onClick={() => {
                                    selectProductVersion(productVersion);

                                    setQueryStringValuesWithoutNavigation({
                                        opening_areo: productVersion.opening_areo,
                                        frame_sides_areo: productVersion.frame_sides_areo,
                                        step: 1
                                    });

                                }}>
                                {t('configurator.select')}
                            </Button>
                        ]} />
                </Col>
            );

        })
}

const ProductVersionStep = () => {

    const { t } = useTranslation();

    const { state, selectProductVersion } = useContext(ConfiguratorContext);

    const [filters, setFilters] = useState<{
        sides: string | null,
        width: number | null
    }>(DEFAULT_FILTERS);

    const filtersConfiguration = useMemo(() => {

        return state.productVersions.reduce((acc, productVersion) => {
            if (!acc.sides.includes(productVersion.frame_sides_areo)) {
                acc.sides.push(productVersion.frame_sides_areo);
            }

            if (productVersion.min_passage_width < acc.widths[0]) {
                acc.widths[0] = productVersion.min_passage_width;
            }

            if (productVersion.max_passage_width > acc.widths[1]) {
                acc.widths[1] = productVersion.max_passage_width;
            }

            return acc;
        }, {
            sides: [],
            widths: [state.productVersions[0]?.min_passage_width || 0, 0]
        });

    }, [state.productVersions]);

    const dataSource = useMemo(() => {
        return (state.productVersions || [])
            .filter((productVersion) => {

                let result = [true];

                if (filters.sides) {
                    result.push(productVersion.frame_sides_areo === filters.sides);
                }

                if (filters.width !== null) {
                    result.push(productVersion.min_passage_width <= filters.width && productVersion.max_passage_width >= filters.width);
                }

                return result.every((r) => r);

            })
    }, [state.productVersions, filters]);

    return (
        <div>
            <PageHeader
                title={t('configurator.select_product_version')}
                subTitle={t('configurator.select_product_version_subtitle')}
            />

            <Row gutter={[16, 16]}>
                <Col xs={FILTERS_DISABLED ? 0 : 24} lg={FILTERS_DISABLED ? 0 : 6}>
                    <div className="filters">
                        <Flex justify="space-between">
                            <Typography.Title className="mb-2 mt-0" level={4}>{t('configurator.filters')}</Typography.Title>
                            <Button size="small" onClick={() => {
                                setFilters(DEFAULT_FILTERS);
                            }}>
                                {t('configurator.cancel')}
                            </Button>
                        </Flex>
                        <Divider />
                        <Space direction="vertical" size="large">
                            <div>
                                <Typography.Title type="warning" className="mb-2 mt-0" level={5}>{t('configurator.passage_filter')}</Typography.Title>
                                <Slider
                                    tooltip={{
                                        formatter: (value) => `${value} mm`
                                    }}
                                    range={false}
                                    min={filtersConfiguration.widths[0]}
                                    max={filtersConfiguration.widths[1]}
                                    value={filters.width || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            width: value
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <Typography.Title type="warning" className="mb-2 mt-0" level={5}>{t('configurator.frame_sides_filter')}</Typography.Title>
                                <Radio.Group
                                    value={filters.sides}
                                    onChange={(e) => {

                                        setFilters({
                                            ...filters,
                                            sides: e.target.value
                                        });

                                    }}>

                                    {(filtersConfiguration.sides || []).map((sides, index) => {
                                        return (
                                            <Radio key={index} value={sides}>{sides}</Radio>
                                        );
                                    })}
                                </Radio.Group>
                            </div>
                        </Space>
                    </div>
                </Col>
                <Col xs={24} lg={FILTERS_DISABLED ? 24 : 18}>
                    <Row gutter={[16, 16]}>
                        <ProductsList
                            dataSource={dataSource}
                            selectProductVersion={selectProductVersion}
                            t={t}
                        />
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default ProductVersionStep;
import { Typography } from "antd";
import React from "react";
import BoxedContainer from "../../components/UI/BoxedContainer";

const DataProtection = () => {
    return (
        <BoxedContainer>
            <div className="base-container">
                <Typography.Title level={2}>
                    Datenschutzerklärung
                </Typography.Title>
                <Typography.Paragraph>
                    Der sorgsame Umgang mit Ihren personenbezogenen Daten ist uns ein Anliegen. Wenn Sie unsere Website besuchen und deren Funktionen nutzen, verarbeiten wir und unsere Dienstleister:innen Ihre Daten auf Grundlage der einschlägigen gesetzlichen Bestimmungen DSGVO, DSG, TKG 2021).
                    Im Folgenden finden Sie einen Überblick über die konkreten Datenverarbeitungen und deren Rechtsgrundlagen sowie die Rechte, die Ihnen zustehen.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    Wir und unsere Dienstleister:innen verarbeiten zudem nur die für den jeweiligen Zweck unbedingt erforderlichen, personenbezogenen Daten. Unser Ziel ist hierbei stets möglichst wenig externe Dienstleister:innen einzusetzen.
                    Eine Weitergabe Ihrer Daten an Dritte erfolgt darüber hinaus, abseits von gesetzlichen Verpflichtungen oder behördlichen/gerichtlichen Entscheidungen, nicht.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <strong>Personenbezogene Daten,</strong> im Folgenden auch kurz „Daten“, sind hierbei alle Informationen, die sich auf eine identifizierte oder identifizierbare lebende Person beziehen (etwa Ihr Name, Ihre IP-Adresse, Ihre Kontaktdaten, der Inhalt eines Cookies, …).
                    Unter der <strong>Verarbeitung von Daten</strong> werden umfassend diverse automatisierte oder manuelle Tätigkeiten wie Erheben, Erfassen, Speichern, Auslesen, Abfragen, Löschen, … von Daten verstanden.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    Die Speicherdauer richtet sich nach der jeweiligen Erfordernis, wobei gesetzliche Vorgaben (etwa in Hinblick auf die Buchhaltung) in Einzelfällen längere Speicherdauern erforderlich machen können.
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Rechtsgrundlagen
                </Typography.Title>

                <Typography.Paragraph>
                    Verantwortliche und Ansprechpersonen für die Verarbeitung von Daten im Sinne der DSGVO auf dieser Website sind:
                    <br />
                    <br />
                    Dietmar Kanitz, Michela Medeot<br />
                    ECLISSE GmbH<br />
                    Perfektastraße 63<br />
                    1230 Wien<br />
                    eclisse@eclisse.at<br />
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Verarbeitete Daten
                </Typography.Title>

                <Typography.Paragraph>
                    Die Verarbeitung personenbezogener Daten erfolgt grundsätzlich entweder aus technischer Notwendigkeit, der Erfordernis von Ihnen gewünschte Leistungen erbringen oder auch um das Angebot optimieren zu können.
                    Aus Gründen der Datensparsamkeit wird auf dieser Website auf den Einsatz von Cookies und vergleichbarer Technologien verzichtet.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <i>Serverlogs im Rahmen Ihres Besuchs unserer Website</i><br />
                    Wenn Sie diese Website nutzen, werden die folgenden Daten der Client-Server-Kommunikation erhoben und in serverseitigen Protokollen gespeichert:
                </Typography.Paragraph>

                <Typography.Paragraph>
                    Daten, die Sie/Ihr Gerät betreffen:<br />
                    <ul>
                        <li>IP-Adresse des anfragenden Geräts</li>
                        <li>Betriebssystem</li>
                        <li>Name und Versionsnummer des Browsers</li>
                        <li>HTTP-Version</li>
                        <li>TLS-Protokoll und -Cipher</li>
                    </ul>
                    Daten, die sich auf den angefragten Inhalt beziehen:<br />
                    <ul>
                        <li>
                            Adresse/URL
                        </li>
                    </ul>
                    Metadaten zum Request:<br />
                    <ul>
                        <li>Datum und Uhrzeit der Anfrage</li>
                        <li>Zugriffsstatus/HTTP-Statuscode</li>
                        <li>Übertragene Datenmenge</li>
                        <li>Dauer des Requests</li>
                    </ul>
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <i>Rechtsgrundlage der Verarbeitung</i><br />
                    Die Verarbeitung der genannten Daten erfolgt aufgrund des berechtigten Interesses, den Betrieb der Website und die Systemsicherheit zu gewährleisten. Die Erfassung dieser Logs, direkt auf der Serverinfrastruktur des Hostinganbieters, stellt dafür das gelindeste Mittel dar.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <i>Empfänger der Daten</i><br />
                    Die Website wird technisch durch einen IT-Dienstleister mit Sitz in der Europäischen Union als Auftragsverarbeiter betrieben. Die Daten aus den Serverlogs werden nur in den gesetzlichen Fällen an Gerichte, Staatsanwaltschaften und Verwaltungsbehörden herausgegeben. Eine darüberhinausgehende Weitergabe an Dritte erfolgt nicht.
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <i>Speicherdauer</i><br />
                    Erfasste IP-Adressen werden ungekürzt für maximal vier Wochen gespeichert.
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Kontaktaufnahme per E-Mail, Telefon, …
                </Typography.Title>

                <Typography.Paragraph>
                    Wenn Sie über die auf dieser Website angegebenen Kontaktdaten (E-Mail, Telefon, …) mit uns Kontakt aufnehmen, werden die im Rahmen Ihrer Kontaktaufnahme übermittelten Daten (Name, E-Mail-Adresse, Ihre konkrete Anfrage) verarbeitet und gespeichert, dies erfolgt jedoch außerhalb der Einfluss- und Betriebssphäre dieser Website. Die Beschreibung der Verarbeitung (Rechtsgrundlage, Empfänger der Daten, Speicherdauer) ist daher auch nicht Teil dieser Datenschutzerklärung. Die zugehörigen Informationen können jederzeit bei uns eingeholt werden.
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Ihre Rechte
                </Typography.Title>

                <Typography.Paragraph>
                    Ihnen stehen bezüglich Ihrer im Rahmen der Benutzung dieser Website gespeicherten Daten grundsätzlich die Rechte auf Widerruf, Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu (Art 7, 15-21 DSGVO).
                </Typography.Paragraph>

                <Typography.Paragraph className="pb-3">
                    Wenn Sie darüber hinaus Gründe sehen, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonstiger Weise verletzt worden sind, können Sie jederzeit bei uns oder der
                    <a href="https://www.dsb.gv.at/" rel="noreferrer" target="_blank">&nbsp;Österreichischen Datenschutzbehörde</a> Beschwerde einlegen.
                </Typography.Paragraph>
            </div>
        </BoxedContainer>
    );
};

export default DataProtection;
import React from 'react';
import { Card, Col, Row, Tabs, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type Product = {
    frame_sides_areo: number;
    opening_areo: string;
    min_passage_width?: number;
    max_passage_width?: number;
    min_passage_height?: number;
    max_passage_height?: number;
    min_overall_width?: number;
    max_overall_width?: number;
    min_overall_height?: number;
    max_overall_height?: number;
}

type ProductUserChoice = {
    width: number;
    height: number;
    measureType: string;
}

type ProductCardProps = {
    product: Product;
    actions?: React.ReactNode[];
    userChoice?: ProductUserChoice;
    coverClassName?: string;
    sizeTabTitle?: string | null;
}

const getProductVersionImage = (productVersion: Product): string => {

    let openingAreo = productVersion.opening_areo;

    return `/build/images/configurator/areo-tech/${openingAreo}_frames_${productVersion.frame_sides_areo}.svg`;
}

const extractProductVersionText = (productVersion: Product, t: TFunction): string => {

    const transKey = `products.version.${productVersion.opening_areo}_frames_${productVersion.frame_sides_areo}`;

    return t(transKey);
}

const ProductCard = ({ product, userChoice, actions, coverClassName = '', sizeTabTitle = null }: ProductCardProps) => {

    const { t } = useTranslation();

    const productVersionText = extractProductVersionText(product, t);

    return (
        <Card
            cover={
                <div className={coverClassName}>
                    <Typography.Title className='text-center' level={5}>{productVersionText}</Typography.Title>
                    <img src={getProductVersionImage(product)} alt={productVersionText} />
                </div>
            }
            actions={actions}>
            <Row gutter={[16, 16]}>               

                <Col xs={24} lg={24}>
                    <Tabs size='small' tabPosition="top" defaultActiveKey={userChoice ? '1' : '2'}>
                        {userChoice && (
                            <Tabs.TabPane tab={sizeTabTitle || t('configurator.user_choice')} key="1">
                                <Typography.Text className='text-uppercase pr-2'>{t('configurator.measure_mode')}:</Typography.Text>
                                <Typography.Text strong={true} className='text-uppercase'>
                                    {t(`configurator.${userChoice.measureType}`)}
                                </Typography.Text>
                                <br />
                                <Typography.Text className='text-uppercase pr-2'>{t('configurator.width')}:</Typography.Text>
                                <Typography.Text strong>{userChoice.width} mm</Typography.Text><br />
                                <Typography.Text className='text-uppercase pr-2'>{t('configurator.height')}:</Typography.Text>
                                <Typography.Text strong>{userChoice.height} mm</Typography.Text>
                            </Tabs.TabPane>
                        )}
                        <Tabs.TabPane tab={sizeTabTitle || t('configurator.overall')} key="2">
                            <span>

                                <Trans i18nKey='configurator.min_max_width'
                                    values={{ min: product.min_overall_width, max: product.max_overall_width }}
                                    components={[<span className='primary-color' />]}
                                />
                            </span>
                            <br />

                            <span>
                                <Trans i18nKey='configurator.min_max_height'
                                    values={{ min: product.min_overall_height, max: product.max_overall_height }}
                                    components={[<span className='primary-color' />]}
                                />
                            </span>

                        </Tabs.TabPane>
                    </Tabs>
                </Col>

            </Row>
        </Card>
    );
}

export default ProductCard;
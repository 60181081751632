import React, { createContext, useReducer, useRef, useEffect } from 'react';
import { configuratorReducer } from './reducer';
import { usePersistedReducer } from '../../hooks/usePersistedReducer';
import { isDevelopment } from '../../utils/env';

const STEPS = [0, 1, 2, 3]

export const defaultInitialState = {
    currentStep: 0,
    enabledSteps: [0],
    choice: {
        product: null,
        width: null,
        height: null,
        type: null,
        varaint: null,
    }

};

const validateStep = (step, searchParams, product) => {
    switch (step) {
        case 0:
            return true
        case 1:
            return Boolean(product);
        case 2:
            return Boolean(product) && searchParams.has('width') && searchParams.has('height') && searchParams.has('type');
        case 3:
            return Boolean(product) && searchParams.has('width') && searchParams.has('height') && searchParams.has('type') && searchParams.has('variant_id');
        default:
            return false;

    }
}

const getStepState = (searchParams, product) => {

    const qsStep = Number(searchParams.get('step'));

    const isValidStep = validateStep(qsStep, searchParams, product);

    if (isValidStep) {
        return {
            currentStep: qsStep,
            enabledSteps: STEPS.slice(0, qsStep + 1)
        }
    } else {
        return {
            currentStep: 0,
            enabledSteps: [0]
        }
    }
}

const getInitStateFromSearchParams = (productVersions, searchParams) => {
    if (!searchParams || searchParams.size === 0) {
        return defaultInitialState;
    } else {

        const product = productVersions.find(p => p.opening_areo === searchParams.get('opening_areo') && p.frame_sides_areo === Number(searchParams.get('frame_sides_areo')));

        if (!product) {
            return defaultInitialState;
        }

        let variant = null;

        if (searchParams.has('variant_id')) {
            variant = {
                id: searchParams.get('variant_id'),
            }
        }

        return {
            ...getStepState(searchParams, product),
            choice: {
                product: product,
                width: searchParams.get('width') || null,
                height: searchParams.get('height') || null,
                measureType: searchParams.get('type') || null,
                variant: variant,
            }
        }
    }
}

export const getInitialState = ({ productVersions, searchParams }) => {

    const initState = getInitStateFromSearchParams(productVersions, searchParams);

    return {
        productVersions,
        ...initState
    };
}

export const ConfiguratorContext = createContext();

const { Provider } = ConfiguratorContext;

/***
 * FSA conventions
 * https://github.com/redux-utilities/flux-standard-action
 **/
const getActions = (dispatch) => {
    return {
        nextStep: () => dispatch({ type: 'NEXT_STEP' }),
        prevStep: () => dispatch({ type: 'PREV_STEP' }),
        selectStep: (step) => dispatch({ type: 'SELECT_STEP', payload: step }),
        selectProductVersion: (product) => dispatch({ type: 'SELECT_PRODUCT_VERSION', payload: product }),
        selectMeasure: (measure) => dispatch({ type: 'SELECT_MEASURE', payload: measure }),
        selectVariant: (variant) => dispatch({ type: 'SELECT_VARIANT', payload: variant }),
        resetState: () => dispatch({ type: 'RESET' })
    };
};

const logPreviousState = (action, state) => {
    if(!isDevelopment) return;
    console.group(`BEFORE ACTION %c${action.type}`, 'color: yellow; font-weight: bold;');
    console.log(`Payload[${JSON.stringify(action.payload)}]`);
    console.log('STATE:', state);
    console.groupEnd();
};
const logNextState = (action, state) => {
    if(!isDevelopment) return;
    console.group(`AFTER ACTION %c${action.type}`, 'color: green; font-weight: bold;');
    console.log(`Payload[${JSON.stringify(action.payload)}]`);
    console.log('STATE:', state);
    console.groupEnd();
};


const useConfiguratorReducer = (
    storeKey,
    initialState,
    reducer,
    middlewares = [],
    afterDispatchMiddlewares = []
) => {
    const [state, dispatch] = storeKey
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
        usePersistedReducer(reducer.bind(null, initialState), initialState, storeKey)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
        useReducer(reducer.bind(null, initialState), initialState);

    const currentRef = useRef();

    useEffect(() => {
        if (!currentRef.current) return;
        afterDispatchMiddlewares.map((middleware) => middleware(currentRef.current, state));
    }, [afterDispatchMiddlewares, state]);

    const dispatchUsingMiddleware = (action) => {
        middlewares.map((middleware) => middleware(action, state));
        currentRef.current = action;
        dispatch(action);
    }

    return [state, dispatchUsingMiddleware];
};



/** 
 * @param {*} param0
 */
export const ConfiguratorStateProvider = ({ children, initialState = {}, storeKey = null, reducer = configuratorReducer }) => {
    const init = { ...defaultInitialState, ...initialState };    

    const [state, dispatch] = useConfiguratorReducer(storeKey, init, reducer, [logPreviousState], [logNextState]);

    return <Provider value={{ state, dispatch, ...getActions(dispatch) }}>{children}</Provider>;
};
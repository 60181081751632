import React from 'react';
import { ConfigProvider, Layout, Grid, Typography, Space } from 'antd'
import { Link, Outlet } from 'react-router-dom';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../constants';
import './App.css';
import BoxedContainer from '../components/UI/BoxedContainer';
import { ScrollRestoration } from 'react-router-dom';

const { Header, Footer, Content } = Layout;

const { useBreakpoint } = Grid;

const App = () => {

    const screens = useBreakpoint();

    return (
        <ConfigProvider
            theme={{
                cssVar: true,
                hashed: false,
                token: {
                    colorPrimary: PRIMARY_COLOR,
                    colorFillSecondary: SECONDARY_COLOR,
                    colorTextSecondary: SECONDARY_COLOR,
                    colorBorderSecondary: SECONDARY_COLOR,
                    colorBgContainer: '#fff',
                    colorLink: PRIMARY_COLOR,
                    borderRadius: 10,
                    colorTextHeading: PRIMARY_COLOR,
                    colorWarning: SECONDARY_COLOR,
                    fontFamily: 'AvenirNext LT Pro',
                    fontSize: 16,
                    fontSizeSM: 14,
                    fontSizeLG: 18,
                    fontSizeXL: 20,
                    fontWeightStrong: 500,
                    fontSizeHeading1: 48,
                    fontSizeHeading2: 32,
                    fontSizeHeading3: 24,
                    fontSizeHeading4: 20,
                    fontSizeHeading5: 18,
                    fontSizeHeading6: 16,
                },

                components: {
                    Layout: {
                        headerBg: '#fff',
                    },
                    Button: {
                        defaultActiveColor: SECONDARY_COLOR,
                        defaultActiveBorderColor: SECONDARY_COLOR,
                        defaultHoverBorderColor: SECONDARY_COLOR,
                        defaultHoverColor: SECONDARY_COLOR,
                    },
                }
            }}
        >

            <Layout>
                <Header className='header'>
                    <BoxedContainer>
                        <Link to="/"><img className='logo' src="/build/images/logo-eclisse.svg" /></Link>
                    </BoxedContainer>
                </Header>
                <Content className='content'>
                    <Outlet />
                </Content>
                <Footer>
                    <BoxedContainer>
                        <div className="text-center">
                            <Typography.Title level={5}>ECLISSE GmbH</Typography.Title>
                            <Typography.Paragraph>
                                <a href="https://www.eclisse.at/" rel="noopener" target="_blank">www.eclisse.at</a>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Space direction={!screens.md ? 'vertical' : 'horizontal'}>

                                    <Link className='text-uppercase' to="/impressum" >Impressum</Link>

                                    <Link className='text-uppercase' to="/datenschutz" >Datenschutz</Link>

                                    <a className='text-uppercase' href="https://www.eclisse.at/de-at/kontakt/" rel="noopener" target="_blank">Kontakt</a>

                                    <a className='text-uppercase' href="https://www.eclisse.at/de-at/markenrichtlinien/" rel="noopener" target="_blank">Markenrichtlinie</a>

                                    <a className='text-uppercase' href="https://www.eclisse.at/de-at/agb/" rel="noopener" target="_blank">AGB</a>

                                </Space>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                Technische Änderungen vorbehalten. Eine eventuelle technische Änderung stellt keinen Reklamationsgrund dar.<br /> Die Zeichnungen sind Eigentum der ECLISSE s.r.l.
                            </Typography.Paragraph>
                        </div>
                    </BoxedContainer>
                </Footer>
            </Layout>         
            <ScrollRestoration />   
        </ConfigProvider >

    );
}

export default App;
import React from "react";
import BoxedContainer from "../../components/UI/BoxedContainer";
import { Typography } from "antd";

const PrivacyContacts = () => {
    return (

        <BoxedContainer>
            <div className="base-container">
                <Typography.Title level={2}>
                    Impressum
                </Typography.Title>

                <Typography.Title level={4}>
                    Medieninhaber
                </Typography.Title>

                <Typography.Paragraph>
                    ECLISSE GmbH<br />
                    Perfektastraße 63<br />
                    1230 Wien
                </Typography.Paragraph>

                <Typography.Paragraph>
                    <a href="https://www.eclisse.at/" rel="noreferrer" target="_blank">www.eclisse.at</a><br />
                    <a href="mailto:eclisse@eclisse.at">eclisse@eclisse.at</a><br />
                    <a href="tel:+4319616565">+43 (1) 961 65 65</a>
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Geschäftsführende Gesellschafter
                </Typography.Title>
                <Typography.Paragraph>
                    Dietmar Kanitz, Michela Medeot
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Unternehmensgegenstand
                </Typography.Title>
                <Typography.Paragraph>
                    Handel mit Baustoffen und Flachglas, Baustoffe- und Flachglaseinzel- und -großhandel
                </Typography.Paragraph>

                <Typography.Title level={4}>
                    Blattlinie
                </Typography.Title>
                <Typography.Paragraph>
                    Information über Waren und Dienstleistungen des Unternehmens, sowie Förderung des Absatzes derselben.
                </Typography.Paragraph>

                <Typography.Paragraph className="pb-3">
                    <a href="https://www.wkoecg.at/ecg.aspx?firmaid=e0f9bfe1-bb27-4e94-be07-531e3af5c2c3" rel="noreferrer" target="_blank">Angaben nach ECG</a>
                </Typography.Paragraph>
            </div>
        </BoxedContainer>
    );
};

export default PrivacyContacts;
import React from "react";
import { Row, Col } from "antd";

const BoxedContainer = ({ children }) => {
    return (
        <Row>
            <Col xs={24} lg={{ span: 22, offset: 1 }} xl={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }}>
                {children}
            </Col>
        </Row>
    );
};

export default BoxedContainer;